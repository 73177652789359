<template>
    <div class="app flex-row align-items-center">
      <div class="container">
        <h2>{{ $t('searchTermStatisticList') }}</h2>
        <date-range-selector
          :start-date="startDate"
          :end-date="endDate"
          @dateRangeUpdated="dateRangeUpdated"
        />
        <grid-view-table
          v-if="startDate && endDate && interval"
          id="statistic-search"
          :columns="columns"
          :path="gridViewDataUrl"
          :default-sort="'count,DESC'"
          :per-page="20"
        />
      </div>
    </div>
  </template>

  <script>
    import GridViewTable from '@/components/GridViewTable';
    import DateRangeSelector from '@/components/DateRangeSelector';
    import { columns } from './grid-views/search-terms';

    export default {
      name: 'SearchTermList',
      components: {
        GridViewTable,
        DateRangeSelector,
      },
      data: () => {
        return {
          columns,
          startDate: null,
          endDate: null,
          interval: null,
        }
      },
      methods: {
        dateRangeUpdated({ start, end, interval }) {
          this.startDate = start;
          this.endDate = end;
          this.interval = interval;
        },
      },
      computed: {
        gridViewDataUrl() {
          return `statistic-search/${this.startDate}/${this.endDate}/${this.interval}`;
        }
      },
    }
  </script>
