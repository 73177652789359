<template>
  <b-row style="margin-top: 12px;">
    <b-col sm="6" lg="3">
      <span>{{ $t('startDate') }}</span>
      <date-picker
        v-model="$v.form.start.$model"
        :config="dateOptions"
      />
    </b-col>
    <b-col sm="6" lg="3">
      <span>{{ $t('endDate') }}</span>
      <date-picker
        v-model="$v.form.end.$model"
        :config="dateOptions"
      />
    </b-col>
    <b-col sm="6" lg="3">
      <span>{{ $t('interval') }}</span>
      <b-form-select v-model="$v.form.interval.$model" :options="intervals"/>
    </b-col>
    <b-col sm="6" lg="3" style="margin-top: 20px;">
      <b-button variant="primary" @click="emitDateUpdateEvent">
        {{ $t('apply') }}
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
  import moment from "moment";
  import i18n from '@/plugins/i18n';
  import datePicker from 'vue-bootstrap-datetimepicker';
  import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
  import { required } from "vuelidate/lib/validators";

  export default {
    components: {
      datePicker
    },
    props: {
      startDate: Date | null,
      endDate: Date | null,
      dateInterval: Number | null,
    },
    data: () => {
      return {
        form: {
          start: null,
          end: null,
          interval: 1,
        },
        intervals: [
          { value: 1, text: i18n.t('days') },
          { value: 2, text: i18n.t('weeks') },
          { value: 3, text: i18n.t('months') },
        ],
        dateOptions: {
          format: 'DD.MM.YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          locale: 'ru',
        }
      }
    },
    validations: {
      form: {
        start: { required },
        end: { required },
        interval: { required },
      }
    },
    beforeMount() {
      this.form.end = this.endDate ? moment(this.endDate).format('DD.MM.YYYY') : moment().format('DD.MM.YYYY');
      this.form.start = this.startDate ? moment(this.startDate).format('DD.MM.YYYY') : moment().subtract(1, 'weeks').format('DD.MM.YYYY');
      this.emitDateUpdateEvent();
    },
    methods: {
      emitDateUpdateEvent() {
        const event = {
          start: moment(this.form.start, 'DD.MM.YYYY').format('YYYY-MM-DD'),
          end: moment(this.form.end, 'DD.MM.YYYY').format('YYYY-MM-DD'),
          interval: this.form.interval,
        };
        this.$emit('dateRangeUpdated', event);
      }
    },
  }
</script>
